<!--
 * @Author: your name
 * @Date: 2020-04-23 16:12:30
 * @LastEditTime: 2021-01-29 16:02:40
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /cunw-youzhiduo-OS/src/views/useHelp/index.vue
-->

<template>
  <div class="concept">
    <!-- <section class="concept-banner">
      <div class="banner-center">
        <h2>
          智慧家教新主张
          重塑学习仪式感
        </h2>
      </div>
    </section> -->
    <section class="main-content">
      <img src="../../assets/images/productConcept/conceptImg.jpg">
      <div class="word">
        <p>
          课桌先生智能家教学习桌是一款“互联网+”思维下的创新产品，以培育新时代创新人才为设计理念。其产品创始人作为一名陪伴孩子成长十余年的父亲，凭借对孩子浓郁而深远的关怀、强烈的责任心，他一直在思考如何让孩子学习更轻松、有趣、快乐；如何避免良莠不齐的网络信息影响；如何让家长在繁忙的工作中也能实现陪伴孩子学习；如何能激发孩子创造性，培养终身受益的学习能力和习惯。多年来，产品创始人将自己的主要精力投入到教育这方热土，将对子女深厚的爱倾注在课桌先生设计理念中，将普天下父母的心声，融入像珍珠一样珍贵的“家庭课堂”。经过反复酝酿与打磨，“课桌先生”孕育而生，理想落地生根。
        </p>
        <br>
        <p>
          给孩子最好的教育是“授之以鱼不如授之以渔”，取名“课桌先生”，是希望在“互联网+教育”时代，汇人之慧、赋物以智，通过课桌这位智能家教先生，让智慧穿透“云”层，传递“光子”予学习者的心灵，创建智慧学习情境，让学生自主学习。以人文关怀，尽最大可能去帮助学生，培养孩子兴趣、信心、独立、理想等精神生命成长情感元素，引导对真善美的追求。而父母要做的就是陪伴、关注、守望，以一个引导者的角色，看着孩子走向自己的未来，拥抱幸福人生。
        </p>
        <br>
        <p>
          以生态视野将学生看作不断生成、不断建构的成长体，具有认知品质又充满情感张力的生命个体，做有温度的教育，是我们的出发点。对学生来说，更是一次触摸梦想，全新学习方式的体验。
        </p>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'Concept',
  data() {
    return {
    };
  },
  methods: {}
};
</script>

<style scoped lang="scss">
@media screen and (min-width: 1400px) {
  .concept{
    width: 100%;
    height: auto;
    background: #F5F5F5;
    overflow: hidden;
    .concept-banner{
      width: 1920px;
      height: 623px;
      margin: 0 auto;
      background: url('../../assets/images/productConcept/conceptBg.png') no-repeat;
      .banner-center{
        width: 1200px;
        margin: 0 auto;
        padding-top: 140px;
        h2{
          width: 528px;
          font-size: 70px;
          font-weight: bold;
          line-height: 98px;
          color: #41B1E6;
        }
      }
    }
    .main-content{
      // margin: -192px auto 195px;
      margin: 50px auto;
      width: 1400px;
      background: #fff;
      height: auto;
      padding: 60px 76px;
      display: flex;
      justify-content: space-between;
      img{
        width: 636px;
        height: auto;
      }
      .word{
        margin-left: 74px;
        flex: 1;
      }
      p{
        font-size: 18px;
        line-height: 27px;
        color: #707070;
        text-indent: 36px;
        text-align: justify;
      }
    }
  }
}
@media screen and (min-width: 1025px) and (max-width: 1400px) {
.concept{
    width: 100%;
    height: 100%;
    background: #fff;
    overflow: hidden;
    // .concept-banner{
    //   width: 1920px;
    //   height: 623px;
    //   margin: 0 auto;
    //   background: url('../../assets/images/productConcept/conceptBg.png') no-repeat;
    //   .banner-center{
    //     width: 1200px;
    //     margin: 0 auto;
    //     padding-top: 140px;
    //     h2{
    //       width: 528px;
    //       font-size: 70px;
    //       font-weight: bold;
    //       line-height: 98px;
    //       color: #41B1E6;
    //     }
    //   }
    // }
    .main-content{
      // margin: -192px auto 195px;
      width: 100%;
      background: #fff;
      height: auto;
      padding: .2rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      overflow: hidden;
      img{
        width: 9rem;
        height: 9.2rem;
      }
      .word{
        margin-left: .4rem;
        flex: 1;
      }
      p{
        font-size: .3rem;
        line-height: .44rem;
        color: #707070;
        text-indent: .6rem;
        text-align: justify;
      }
    }
  }
}
@media screen and (max-width: 1025px) {
  .concept{
    width: 100%;
    height: auto;
    background: #fff;
    overflow: hidden;
    padding: .8rem .53rem;
    .main-content{
      img{
        width: 100%;
        height: auto;
        margin-bottom: .48rem;
      }
      p{
        font-size: .373rem;
        line-height: 0.6rem;
        color: #8A8684;
        text-indent: .746rem;
        text-align: justify;
      }
    }
  }
}
</style>
